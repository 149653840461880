.entity-content {
  padding: 24px;
  height: 100%;
  overflow: scroll;

  .side-bar-content {
    border-right: 2px solid #dddddd;
    background: white !important;
    padding: 0;

    .ant-layout-sider-trigger {
      width: 23px !important;
      height: 23px;
      position: absolute;
      top: 24px;
      right: -12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: white;
      color: #0f192e;
      border: 1px solid #dfd5d5;
      font-size: 12px;
    }

    .radio-group-container {
      width: 100%;
      display: flex;

      .radio-group-button {
        text-align: center;
        flex: 1;

        &-no-border {
          border: 0;
          outline: 0;
          box-shadow: none;

          &:before {
            display: none !important;
          }
        }
      }
    }

    .categories-tree-add-btn {
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .taxonomy-tabs {
    height: 100%;

    .ant-tabs {
      height: 100%;

      &-tab {
        padding-top: 24px;
      }

      &-content-holder {
        position: relative;
        overflow: auto;
      }

      &-nav {
        padding: 0 12px;
        margin-bottom: 0;
        background: white;
        height: 55px;
      }

      &-content {
        height: 100%;
      }
    }
  }
}
