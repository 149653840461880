.App {
  height: 100%;

  .ant-layout {
    height: calc(100vh - 48px);
  }
}

.page-content {
  width: 100%;
  height: 100%;
}

.capitalized {
  text-transform: capitalize;
}

.ant-tree-show-line .ant-tree-switcher,
.ant-tree {
  background-color: transparent !important;
}

.content-tree-title {
  font-weight: 700;
  margin: 12px 0;
}

.ant-tree {
  margin-top: 12px!important;
}

::-webkit-scrollbar {
  display: none;
}