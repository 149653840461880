.properties-content {
  position: relative;
  padding: 24px;

  padding-bottom: 48px;

  .ant-form-item-label {
    display: none;
  }
  .properties-add-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-title {
    font-size: 20px;
    font-weight: bold;
    display: inline;
  }

  .ant-divider-with-text {
    text-transform: capitalize;

    &::before,
    &::after {
      border-top: 1px solid #dadada !important;
    }
  }

  .properties-fields {
    padding: 0 24px;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 7px;
      border-radius: 0;
      box-shadow: none;

      // &:hover {
      // }

      .ant-input-number {
        width: 100%;
      }

      .header {
        display: flex;
        justify-content: space-between;

        .labels {
          h4 {
            color: rgb(9, 14, 36);
          }

          h5 {
            color: rgb(98, 110, 153);
          }
        }
      }

      .input {
        margin-bottom: 0px;
        &.error {
          box-shadow: 0 0 10px red;
        }

        &.success {
          box-shadow: 0 0 10px rgb(48, 181, 43);
        }
      }
    }
  }

  .properties-template-upload {
    display: flex;
    padding: 0;

    &-readonly {
      .ant-upload-list-item-actions {
        button {
          display: none;
        }
      }
    }
  }

  .properties-template-drag-drop {
    padding: 0 24px;

    &-content {
      display: flex;

      .ant-upload-drag {
        width: 300px;
        height: auto;
      }
    }

    &-add-tem {
      margin-top: 24px;
      margin-left: 120px;
    }
  }
}

.radioButtonIcon {
  max-width: 12px;
  margin-right: 7px;
}

.ant-radio-button-wrapper {
  white-space: nowrap;
  padding-right: 30px !important;
}

.copyEntitySettings {
  text-align: center;
  margin-top: 7px;
  margin-bottom: 14px;

  .title {
    font-weight: bold;
    font-size: 12pt;
  }
  .subtitle {
    display: block;
    font-weight: bolder;
  }
  .clientSelect {
    display: block;
    min-width: 150px;
    margin-bottom: 14px;
  }
  .entitiesSelect {
    min-width: 150px;
  }
}

.properties-save-button {
  &:hover {
    transform: translateX(-50px);
    opacity: 1;
  }
  transition: transform 1s, opacity 1s;

  cursor: pointer;
  appearance: none;
  background-color: #1890ff;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: -40px;
  width: 55px;
  height: 55px;
  border: none;
  border-radius: 30px;
  text-align: center;
  font-size: 20px;
  padding: 13px;
  color: white;
  z-index: 100;
  opacity: 0.8;
}
