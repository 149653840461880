.taxonomy-sidebar {
  position: relative;
  height: 100%;
  overflow: auto;
  padding: 20px;

  &-category-modal {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  &-tree-content {
    padding: 24px 0;

    .ant-tree-treenode {
      align-items: center !important;
    }

    .custom-tree-node {
      .ant-dropdown-trigger {
        //display: none;
      }

      .ant-dropdown-button {
        button:focus,
        button:hover {
          background: none;
        }
      }

      &:hover {
        .ant-dropdown-trigger {
          //display: block;
        }
      }

      .ant-dropdown-open {
        display: block !important;
      }
    }
  }
}

.tree-total-count {
  margin: 10px;
  text-align: center;
  font-style: italic;
}

.root {
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}
